import axios from "axios";
import React, { useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";

import { Map } from "../components/LandingComponent/Map";
import awsconfig from "../config/awsconfig";
import { InputSearchCity } from "./UtilComponents";

const sharingPointsUrl =
  awsconfig.REACT_APP_PUBLIC_API + "/sharingpoints/public_sharingpoints";

export default ({ latLng, ownLocation }) => {
  const [location, setSelectedLocation] = useState();
  const [stations, setStations] = useState();
  const [geoCode, setGeoCode] = useState();

  const fetchSharingPoints = async () => {
    const { data } = await axios.get(sharingPointsUrl, {
      params: { location },
    });

    setStations(data.data);
  };

  useEffect(() => {
    fetchSharingPoints();
  }, [location]);
  return (
    <>
      {stations ? (
        <div style={{ height: "100vh" }}>
          <InputSearchCity
            {...{
              setSelectedLocation,
              location,
              city_search: true,
              setGeoCode,
            }}
            style={"SWBPublicLocationDropdownSearch"}
          />
          <Map
            {...{
              sharingPoints: stations,
              latLng: location
                ? [location.item.latitude, location.item.longitude]
                : latLng,
              ownLocation,
              flyToGeoCode: geoCode
            }}
            hasUserLocationButton
            hasZoomControl
          />
        </div>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader active inline size="huge" />
        </div>
      )}
    </>
  );
};
