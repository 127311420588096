/** @format */

import React, { useContext } from "react";
import { Confirm } from "semantic-ui-react";

import { restApiResponse } from "../../store/actions/restApiActions";
import { useDispatch, useSelector } from "react-redux";
import { AxiosContext } from "../../context/AxiosContext";

const DeleteGroup = ({ setIsOpen, isOpen, group }) => {
  const dispatch = useDispatch();
  const { axiosInstance } = useContext(AxiosContext);

  const submit = async () => {
    let response;
    await axiosInstance
      .delete(`/user_group/${group}`)
      .then(result => {
        response = {
          message: `The Group ${group} was deleted successfully`,
          status_code: result.data.status_code
        };
        dispatch(restApiResponse(response));
        setIsOpen(false);
      })
      .catch(error => {
        console.log("error", error);
      });
  };
  return (
    <Confirm
      size="mini"
      open={isOpen}
      content={`Are you sure you want to delete the Group ${group}?`}
      header="Confirmation"
      onConfirm={submit}
      onCancel={() => {
        setIsOpen(false);
      }}
    />
  );
};

export default DeleteGroup;
