import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import FieldArray from "./DaysArray";
import {
  Grid,
  Form,
  Container,
  Button,
  Message,
  GridRow,
  GridColumn,
  Segment,
  Divider,
} from "semantic-ui-react";
import { navigate } from "@reach/router";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { CustomButton } from "../CustomComponents";

function OpeningHours({
  isEdit,
  state,
  setState,
  setCurrentStep,
  setValidSteps,
  locationHashKey,
}) {
  const { t } = useTranslation();
  const { control, register, handleSubmit, getValues, errors, setValue } =
    useForm({
      defaultValues: state,
    });

  const navigateToLocationPage = () => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("edit") && !locationHashKey) {
      navigate("/locations");
    }
  };

  useEffect(() => {
    setCurrentStep("OpeningHours");
    navigateToLocationPage();
  }, []);

  const onSubmit = (data) => {
    setValidSteps((current) => {
      return {
        ...current,
        OpeningHours: true,
      };
    });
    setState((current) => ({ ...current, ...data }));
    navigate("step3");
  };

  const onClickHandler = (event) => {
    event.preventDefault();
  };

  return (
    <Container style={{ width: "830px" }}>
      <Segment>
        <Message
          icon="info"
          info
          header={"Information"}
          content={t("locations.openingtimes.notice")}
        />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <FieldArray
                  {...{ control, register, getValues, errors, setValue }}
                  defaultValues={state}
                />
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <GridRow
              style={{ display: "flex", justifyContent: "space-between", padding: 14 }}
            >
              <CustomButton
                onClick={() => {
                  navigate(`step1`);
                }}
                type="button"
                basic
                title={"Back"}
                translate
                negative
              />

              <CustomButton
                type="submit"
                primary
                style={{
                  width: 100,
                }}
                title={"Next"}
              />
            </GridRow>
          </Grid>
        </Form>
      </Segment>
    </Container>
  );
}

export default OpeningHours;
