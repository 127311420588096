/** @format */

import React from "react";
import { Menu } from "semantic-ui-react";

import "./index.scss";

import SideBarItem from "./SideBarItem";
import { RESOURCE_METHODS, SWOBBEE_RESOURCES } from "../../constants/app-constants";
import { method } from "lodash";

interface SideBarListProps {
  collapsed: boolean;
}

const SideBarList = (props: SideBarListProps) => {
  const { collapsed } = props;
  return (
    <aside>
      <nav>
        <Menu icon="labeled" inverted vertical>
          {navLinks.map((item) => (
            <SideBarItem collapsed={collapsed} item={item} key={item.href} />
          ))}
        </Menu>
      </nav>
    </aside>
  );
};

export default SideBarList;

const navLinks = [
  {
    permission: "SharingPoint",
    href: "/sharingpoints",
    label: "mainsidebar.sharingpoints.label",
    icon: "lightning",
    resource: SWOBBEE_RESOURCES.SHARINGPOINTS,
    method: RESOURCE_METHODS.GET
  },
  {
    permission: "SharingPoint",
    href: "/statistics",
    label: "mainsidebar.statistics.label",
    icon: "chart bar outline",
    resource: SWOBBEE_RESOURCES.SWAPS,
    method: RESOURCE_METHODS.GET
  },

  {
    permission: "NewBattery",
    href: "/batteries",
    label: "mainsidebar.batteries.label",
    icon: "battery full",
    resource: SWOBBEE_RESOURCES.BATTERIES,
    method: RESOURCE_METHODS.GET

  },
  {
    permission: "SharingPoint",
    href: "/battery-tracking",
    label: "Battery-Tracking",
    icon: "dashboard",
    resource: SWOBBEE_RESOURCES.BATTERY_TRACKING,
    method: RESOURCE_METHODS.GET
  },
  {
    permission: "ErrorHistory",
    href: "/locations",
    label: "mainsidebar.locations.label",
    icon: "point",
    resource: SWOBBEE_RESOURCES.LOCATIONS,
    method: RESOURCE_METHODS.GET

  },
  {
    permission: "ErrorHistory",
    href: "/",
    label: "Error History",
    icon: "warning sign",
    resource: SWOBBEE_RESOURCES.ERROR_HISTORY,
    method: RESOURCE_METHODS.GET

  },

  {
    permission: "SharingPoint",
    href: "/user-management",
    label: "User-Management",
    icon: "user plus",
    resource: SWOBBEE_RESOURCES.USERS,
    method: RESOURCE_METHODS.GET

  },
  {
    permission: "ErrorHistory",
    href: "/release-notes",
    label: "mainsidebar.releaseNotes.label",
    icon: "newspaper outline",
    resource: SWOBBEE_RESOURCES.RELEASE_NOTES,
    method: RESOURCE_METHODS.GET
  },
];
