/** @format */

import React, { useEffect, useState, useMemo, useContext } from "react";
import {
  Grid,
  Container,
  GridRow,
  Card,
  Icon,
  Segment,
  Loader,
  GridColumn,
  Label,
  Button,
  Header,
  CardContent,
  CardHeader,
  Input,
  Breadcrumb,
} from "semantic-ui-react";
import { useDispatch } from "react-redux";
import NotesCard from "../../components/Notes/NotesCard";
import LocationSettingsCard from "../../components/Locations/LocationSettingsCard";
import "./../../components/Locations/style.scss";
import { isAllowedToRead } from "../../helper/permissions";
import { SimpleMap } from "../../components/helpers/maps/SimpleMarkerMap";
import { Link, navigate } from "@reach/router";
import { AxiosContext } from "../../context/AxiosContext";
import { restApiResponse } from "../../store/actions";

export default (props) => {
  const [locationsDetailData, setLocationsDetailData] = useState();
  const [newGeoCode, SetNewGeoCode] = useState({ latitude: 0, longitude: 0 });

  const [editGeoCode, setEditGeoCode] = useState();
  // const [swapReport, setSwapReport] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [isSavingSettings, setIsSavingSettings] = useState(false);
  const [locationSettings, setLocationSettings] = useState({});
  const [locationSettingsMeta, setLocationSettingsMeta] = useState();

  const [notes, setNotes] = useState([]);

  const [notesError, setNotesError] = useState(false);

  const [notesIsFetching, setNotesIsFetching] = useState(false);
  const [isSavingNote, setIsSavingNote] = useState(false);
  const { axiosInstance } = useContext(AxiosContext);
  const dispatch = useDispatch();

  const submitFunc = (data) => {
    if (!isSavingSettings) saveSettings(data);
  };

  const locationId = useMemo(() => {
    return props.locationId;
  }, [props.locationId]);

  const fetchNotes = async () => {
    try {
      setNotesIsFetching(true);
      setNotesError(false);
      const { data } = await axiosInstance.get(
        `/notes/${locationId}?context=Locations`
      );
      setNotes(data.data);
      setNotesIsFetching(false);
    } catch (error) {
      setNotesError(true);
    }
  };

  const putNote = async (data) => {
    try {
      setIsSavingNote(true);
      await axiosInstance.put(`/notes`, {
        context: "Locations",
        context_hash_key: locationId,
        note_hash_key: data.selectedNote,
        note_text: data.noteText,
        title: data.title,
      });
      fetchNotes();
      setIsSavingNote(false);
    } catch (error) {
      setNotesError(error);
    }
  };

  const postNote = async (data) => {
    try {
      setIsSavingNote(true);
      await axiosInstance.post(`/notes`, {
        context: "Locations",
        context_hash_key: locationId,
        note_text: data.noteText,
        title: data.title,
      });
      fetchNotes();
      setIsSavingNote(false);
    } catch (error) {
      setNotesError(error);
    }
  };

  const fetchLocationById = async () => {
    try {
      const { data } = await axiosInstance.get(
        "/locations/" + props.locationId
      );
      setLocationsDetailData(data.data);
      if (data.data.settings) {
        setLocationSettings(data.data.settings);
      }
    } catch (error) {
      let response = {
        message: error.response.data.message,
        status_code: error.response.data.status_code,
      };
      dispatch(restApiResponse(response));
    }
  };

  const fetchSettingsMetaData = async () => {
    try {
      const { data } = await axiosInstance.get(
        `/settings/settings_meta_data/location_settings`
      );
      setLocationSettingsMeta(data.data);
    } catch (error) {
      let response = {
        message: error.response?.data?.message,
        status_code: error.response?.data?.status_code,
      };
      dispatch(restApiResponse(response));
    }
  };

  const saveSettings = async (settings) => {
    try {
      // console.log('settings: ', settings)
      const { data } = await axiosInstance.put(
        "/settings/location_settings/" + locationId,
        settings
      );
      let response = {
        message: "Successfully update the settings",
        request_parameter: "",
        status_code: 200,
      };
      dispatch(restApiResponse(response));
      setIsEditing(false);
    } catch (error) {
      let response = {
        message: error.response.data.message,
        status_code: error.response.data.status_code,
      };
      dispatch(restApiResponse(response));
    }
  };

  useEffect(() => {
    fetchLocationById();
    fetchNotes();
    fetchSettingsMetaData();
  }, [locationId, axiosInstance]);

  const putLocationState = async () => {
    await axiosInstance.put(`/locations/${locationId}/state`, {
      state: locationsDetailData.state === "aktiv" ? "inaktiv" : "aktiv",
    });
    fetchLocationById();
  };

  const putLocationGeoCode = async () => {
    await axiosInstance.put(`/locations/${locationId}/geocode`, {
      latitude: newGeoCode.latitude
        ? newGeoCode.latitude
        : locationsDetailData.gps_latitude,
      longitude: newGeoCode.longitude
        ? newGeoCode.longitude
        : locationsDetailData.longitude,
    });
    fetchLocationById();
    setEditGeoCode(false);
  };

  return (
    <Container style={{ color: "black" }} className="show-location-container">
      {!locationsDetailData && <Loader inline active size="large" />}
      {locationsDetailData && (
        <Grid>
          <GridRow>
            <Segment style={{ width: "100%", padding: "0px" }}>
              <div style={{ height: "300px", width: "100%" }}>
                <SimpleMap
                  latLng={[
                    locationsDetailData.gps_latitude,
                    locationsDetailData.gps_longitude,
                  ]}
                />
              </div>
            </Segment>
          </GridRow>
          <GridRow>
            <Card style={{ textAlign: "left" }} fluid>
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: 8,
                }}
              >
                <Header
                  style={{
                    paddingBottom: 0,
                    marginBottom: 0,
                  }}
                >{`${locationsDetailData.street} ${locationsDetailData.street_number}, ${locationsDetailData.zipcode} ${locationsDetailData.city}  `}</Header>
                <div>
                  <Button
                    style={{ paddingRight: "10px" }}
                    basic
                    circular
                    onClick={() =>
                      navigate(
                        `/locations/edit/${locationsDetailData.hash_key}/step1`
                      )
                    }
                  >
                    <Icon name="edit" />
                  </Button>
                  <Button
                    style={{ padding: "12px 12px 11px 12px" }}
                    content={
                      locationsDetailData.state === "aktiv"
                        ? "Deactivate"
                        : "Activate"
                    }
                    basic
                    circular
                    size="mini"
                    onClick={() => putLocationState()}
                  />
                </div>
              </CardHeader>
              <CardContent style={{ color: "#444" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ padding: 5 }}>
                    <Icon name="point" style={{ fontSize: 14 }} />
                    {editGeoCode && (
                      <>
                        <Input
                          size="mini"
                          defaultValue={locationsDetailData.gps_latitude}
                          placeholder="latitude"
                          onChange={(_, { value }) =>
                            SetNewGeoCode({
                              ...newGeoCode,
                              latitude: value,
                            })
                          }
                        />{" "}
                        <Input
                          size="mini"
                          defaultValue={locationsDetailData.gps_longitude}
                          onChange={(_, { value }) =>
                            SetNewGeoCode({
                              ...newGeoCode,
                              longitude: value,
                            })
                          }
                          placeholder="longitude"
                        />
                      </>
                    )}
                    {!editGeoCode && locationsDetailData.gps_latitude + ","}{" "}
                    {!editGeoCode && locationsDetailData.gps_longitude}{" "}
                    <span style={{ color: "grey", fontSize: "12px" }}>
                      (latitude,longitude){" "}
                      {!editGeoCode && (
                        <Button
                          icon={"edit"}
                          basic
                          size="tiny"
                          circular
                          onClick={() => setEditGeoCode(!editGeoCode)}
                        />
                      )}
                      {editGeoCode && (
                        <Button
                          basic
                          size="tiny"
                          content={"Save"}
                          circular
                          onClick={() => putLocationGeoCode()}
                        />
                      )}
                    </span>
                  </div>
                  <div style={{ padding: 5 }}>
                    <Icon name="globe" />

                    {locationsDetailData.w3w}
                    <span style={{ color: "grey", fontSize: "12px" }}>
                      (w3w)
                    </span>
                  </div>
                  {/* <div style={{ padding: 5 }}>
                    <Icon name="user" />
                    {locationsDetailData.first_name}{" "}
                    {locationsDetailData.family_name}
                  </div>
                  <div style={{ padding: 5 }}>
                    <Icon name="mail" />

                    {locationsDetailData.email}
                  </div>
                  <div style={{ padding: 5 }}>
                    <Icon name="phone" />

                    {locationsDetailData.phone_number}
                  </div> */}

                  <div style={{ padding: 5 }}>
                    <Icon name="question" />

                    <Label
                      color={locationsDetailData.state === "active" && "green"}
                      circular
                      content={
                        locationsDetailData.state === "active"
                          ? "Active"
                          : "Deactivated"
                      }
                    />
                  </div>

                  {locationsDetailData.address_description && (
                    <div style={{ padding: 5 }}>
                      <Icon name="info" />

                      {locationsDetailData.address_description}
                    </div>
                  )}

                  <div style={{ padding: 5 }}>
                    <Icon name="font awesome flag" />

                    {locationsDetailData.stations.map((item, index) => (
                      <Link key={index} to={`/sharingpoints/${item}`}>
                        <Label circular style={{ margin: "5px" }}>
                          {item}
                        </Label>
                      </Link>
                    ))}
                  </div>
                </div>
              </CardContent>
            </Card>
          </GridRow>

          <>
            {isAllowedToRead("Notes") && (
              <GridRow>
                <Card style={{ width: "100%", padding: 14 }}>
                  <LocationSettingsCard
                    locationId={locationId}
                    locationSettings={locationSettings}
                    settingMetaData={locationSettingsMeta}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    saveSettings={saveSettings}
                    isSavingSettings={isSavingSettings}
                    submitFunc={submitFunc}
                    stationList={locationsDetailData.stations}
                  />
                </Card>
              </GridRow>
            )}
            {isAllowedToRead("Notes") && (
              <GridRow style={{ marginBottom: 100 }}>
                <Card style={{ width: "100%", padding: 14 }}>
                  <NotesCard
                    postNote={postNote}
                    putNote={putNote}
                    notesError={notesError}
                    notesIsFetching={notesIsFetching}
                    isSavingNote={isSavingNote}
                    notesByContextHashKey={notes}
                  />
                </Card>
              </GridRow>
            )}
          </>
        </Grid>
      )}
    </Container>
  );
};
