import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AxiosContext } from "./AxiosContext";
import { toast } from "react-toastify";
import { StationContext } from "./StationContext";

export const SwapContext = createContext({
  fetchSwapsBySwapId: (stationId) => {},
  isLoadingSwaps: false,
  swaps: [],
});

export const SwapProvider = ({ children }) => {
  const [swaps, setSwaps] = useState([]);
  const [swap, setSwap] = useState();

  const [isLoadingSwaps, setIsLoadingSwaps] = useState(false);

  const { axiosInstance } = useContext(AxiosContext);
  const { station } = useContext(StationContext);

  const fetchSwapsByStationId = useCallback(
    async (stationId) => {
      try {
        setIsLoadingSwaps(true);
        const { data } = await axiosInstance.get(
          "/swaps/swap_actions?station=" + stationId
        );
        setSwaps(data.data);
        setIsLoadingSwaps(false);
      } catch (error) {
        console.log(error);
        toast.error("Failed to fetch swaps", error.response?.data?.message);
        setIsLoadingSwaps(false);
      }
    },
    [axiosInstance]
  );

  const fetchSwapById = useCallback(async (swapId) => {
    try {
      
      const { data } = await axiosInstance.get("/swaps/" + swapId);
      console.log("swaps", data.data)
      setSwap(data.data);
    } catch (error) {
      toast.error(error.response?.data?.message);
      // navigate("/statistics");
    }
  }, [axiosInstance])

  useEffect(() => {
    if (!station) return;
    fetchSwapsByStationId(station.sp_serial_number);

    return () => {
      
    };
  }, [fetchSwapsByStationId, station]);

  return (
    <SwapContext.Provider
      value={{
        fetchSwapsByStationId,
        isLoadingSwaps,
        swaps,
        fetchSwapById,
        swap
      }}
    >
      {children}
    </SwapContext.Provider>
  );
};
