import React, { useContext, useEffect, useState } from "react";
import { Router } from "@reach/router";
import Layout from "../components/NewLayout";
import { useTranslation } from "react-i18next";

import { AssetsContext } from "../context/AssetsContext";
import SplashScreen from "./SplashScreen";
import { ToastContainer } from "react-toastify";

const AuthenticatedApp = () => {
  const [lang, setLang] = useState();
  const { i18n } = useTranslation();

  const { assets, routes } = useContext(AssetsContext);

  const LANGUAGE = JSON.parse(localStorage.getItem("LANGUAGE"));

  useEffect(() => {
    if (!lang && LANGUAGE) {
      setLang(LANGUAGE);
    }
  }, [LANGUAGE]);

  useEffect(() => {
    if (lang) i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <>
      {!assets && <SplashScreen />}

      {assets && (
        <Layout>
          <Router>
            {routes.map(
              ({ path, component: Component, children, props = {} }, index) => (
                <Component key={index} path={path} {...props}>
                  {children &&
                    children.map((child, i) => (
                      <child.component
                        key={i}
                        path={child.path}
                        {...(child.props || {})}
                      />
                    ))}
                </Component>
              )
            )}
          </Router>
          
        </Layout>
      )}
      <ToastContainer />
    </>
  );
};

export default AuthenticatedApp;
